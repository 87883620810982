<template>
    <div>
        <b-card class="primary-2" text-variant="white" border-variant="primary">
            <b-card-title class="text-white text-center" >
                <feather-icon icon="GridIcon" size="90" />
                <h4 class="text-white mt-1"> {{ dataKelompok.nama_kelompok }}<br><small>{{ dataKelompok.nama_bidang }}</small> </h4> 
                   
            </b-card-title>
            <h5 class="text-white mt-1 text-center">Rekap Presensi  {{tanggal}}  </h5>

            <span class="mb-1">  
                <!-- {{ dataKelompok }} -->
                <span style="font-size: 0.1px;">{{ dataKelompok }}</span><br>
                    <b>Informasi Jam Kerja </b><br>
                    <b-avatar size="20px" rounded="" badge-variant="warning">
                        <feather-icon icon="ClockIcon" size="15" /> 
                    </b-avatar>  Jama Kerja {{ dataKelompok.jam_masuk }} - {{ dataKelompok.jam_pulang }}<br>
                    <b-avatar size="20px" rounded="">
                        <feather-icon icon="FramerIcon" size="15" /> 
                    </b-avatar> Durasi Jam Herja <b>{{dataKelompok.durasi_kerja}}</b> Jam<br>
                    <b-avatar size="20px" rounded="">
                        <feather-icon icon="AlertCircleIcon" size="15" /> 
                    </b-avatar> Toleransi Keterlambatan Presensi  <b>{{dataKelompok.max_terlambat}}</b> Menit
                </span>
                <br> <br><b>Keterangan</b>
                <small><br>{{ dataKelompok.keterangan }}</small>
        </b-card>
    </div>
  </template>
  
  <script>
      import {
          BRow, BCol,BCard, BCardTitle , BAvatar
      } from 'bootstrap-vue' 
    
  export default {
    components:{
        BRow, BCol,BCard,BCardTitle, BAvatar
  
    },
    props:{
        dataKelompok :  {},
        tgl:{}
    },
    data(){
        return{
            tanggal : "",
        }
    },
    mounted(){
        this.tgl_now();
    },
    methods:{
        tgl_now(){
            const nm_bulan = ['Januari', 'Februari','Maret','April','Mei','Juni','Juli','Agustus','September', 'Oktober', 'November','Desember']
            const ddk = new Date(this.tgl)
            this.tanggal = 'Bulan '+ nm_bulan[ddk.getMonth()] +' , Tahun '+ddk.getFullYear();
        }
    }
  
  }
  </script>
  
  <style>
  .primary-2 {
    background-color: #002b63ee !important;
}
  </style>