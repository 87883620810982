<template>
  <div>
    <b-row>
      <b-col md="4"> 
        <card-rekap :dataKelompok="dataKelompok"  :tgl=Tanggal ></card-rekap>
      </b-col>
      <b-col md="8">
        <b-row class="mb-1">
          <b-col md="4"></b-col>
          <b-col md="5">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input placeholder="Search" v-model="cari" />
            </b-input-group>
          </b-col>
          <b-col md="3">
            <router-link to="/laporan_perkelompok_list">
            <b-button block variant="primary" size="sm">
              <feather-icon icon="ChevronsLeftIcon" size="18" />
              Kembali 
            </b-button>
          </router-link>
          </b-col>
        </b-row>
        <div v-for="rsDanggota in rsDanggota.rs" v-bind:key="rsDanggota.user_key">
          <rekap-anggota :rsDanggota=rsDanggota :tgl=Tanggal></rekap-anggota>
        </div>

        <b-row>
          <b-col md="6">
            Total Row <b>{{ rsDanggota.lenght}}</b>
          </b-col>
          <b-col md="6">
            <b-pagination v-model="currentPage" hide-goto-end-buttons :per-page='4' :total-rows="rsDanggota.lenght" @input="nextPage(currentPage)"
              align="right"  />
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
  import axios from '@/config/Axios';
  import Base from '@/config/Mixins_base';
  import {
    mapMutations,
    mapState
  } from 'vuex'
  import {
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
    BPagination,
    BButton,
    BAvatar
  } from 'bootstrap-vue'
  import RekapAnggota from './RekapAnggota.vue'
  import CardRekap from './cardRekap.vue'

  export default {
    components: {
      BRow,
      BCol,
      BCard,
      RekapAnggota,
      CardRekap,
      BInputGroup,
      BFormInput,
      BInputGroupPrepend,
      BInputGroupAppend,
      BFormGroup,
      BFormTextarea,
      BPagination,
      BButton,
      BAvatar
    },
    computed: {
      ...mapState({
        IdKelompok: state => state.laporan.IdKelompok,
        Tanggal: state => state.laporan.Tanggal,
      })
    },
    watch:{
      cari(newData, old) {
        if (newData != NaN) {
          this.load_data_anggota();
        }
      },
      // offset (newData, old) {
      //   if (newData != NaN) {
      //     this.load_data_anggota();
      //   }
      // },
    },
    mixins: [Base],
    mounted() {
      this.cek_token();
      this.load_data_anggota();
      this.get_profil_kelompok();
    },
    data() {
      return {
        cari: "",
        offset: 0,
        rsDanggota: [],
        items: [],
        statistik: '',
        currentPage: 1,
        perPage: 3,
        rows: 0,
        dataKelompok : {}
      }
    },
    methods: {
      ...mapMutations({
        SetIdKelompok: 'SetIdKelompok',
        SetTanggal: 'SetTanggal'
      }),
      nextPage(currentPage){
        const self = this;
        self.offset =  currentPage;
        this.load_data_anggota();
      },
      async load_data_anggota() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/kelompok/load_data_anggota',
            data: {
              id_kelompok: self.IdKelompok,
              cari: self.cari,
              offset: self.offset,
              tgl: self.Tanggal,
              dataKelompok: {}
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rsDanggota = response.data.result;

          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async get_profil_kelompok() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/kelompok/get_profil_kelompok',
            data: {
              id_kelompok: self.IdKelompok,
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.dataKelompok = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
    }

  }
</script>

<style>
  .bg-card {
    background: #0000;
  }
</style>