<template>
    <div> 
        <b-card  border-variant="primary" class="shadow-none">
            <b-row>
                <b-col md="4"> 
                    <small><b>{{ rsDanggota.nm_lengkap }}</b></small><br>
                    <small><b>Jabatan : </b></small> 
                    <small>{{ rsDanggota.nama_jabatan }}</small>
                </b-col>
                <b-col md="5">
                    <span>
                        <b>{{ rsDanggota.jm_presensi }} </b> Kali kehadiran 
                    </span> <br>
                    <span>
                        <b>{{  parseInt(rsDanggota.jm_presensi) - parseInt(rsDanggota.jm_ontime) }}</b>  Presensi Terlambat
                    </span> <br>
                    <span>
                        <b>{{    parseInt(rsDanggota.jm_durasi_sesuai) }} </b> Kehadiran sesuai durasi jam kerja 
                    </span> <br>
                    <span>
                        <b>{{  parseInt(rsDanggota.jm_presensi) - parseInt(rsDanggota.jm_durasi_sesuai) }}  </b> Kehadiran Kurang sesuai durasi Jam kerja 
                    </span> <br>
                </b-col>
                <b-col md="3">
                    <router-link :to="'/laporan_perkelompok_anggota/'+rsDanggota.id_anggota+'/'+ tgl  +'/'+ rsDanggota.user_key">
                        <b-button  block variant="primary" >
                        <feather-icon icon="AlignJustifyIcon" /> Detail Presensi
                    </b-button>
                    </router-link> 
                    <!-- //'+ data.item.hast +data.item.hast +data.item.hast2+'/'+data.item.hast2  + '/'+data.item.id_anggota +'/'+ tgl -->
                    <a :href="baseUrl +'download/laporan_perkelompok/file_data_2/'+isAuthenticated+'/'+rsDanggota.id_anggota +'/'+tgl" target="_blank" class="mt-1">
                    <b-button  block variant="warning" class="mt-1" >
                        <feather-icon icon="DownloadIcon" /> Download
                    </b-button>
                    </a>
                </b-col>
            </b-row>
        </b-card>
       

    </div>
</template>

<script>
import Base from '@/config/Mixins_base';
    import {
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BFormTextarea,
        BPagination,
        BButton,
        BAvatar
    } from 'bootstrap-vue'

    export default {
        components: {
            BRow,
            BCol,
            BCard,
            BInputGroup,
            BFormInput,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormGroup,
            BFormTextarea,
            BPagination,
            BButton,
            BAvatar
        },
        data() {
            return {
                items: [],
                statistik: '',
                currentPage: 1,
                perPage: 3,
                rows: 0,
                cari: '',
                offset: '',
            }
        },
        mixins :[Base],
        props:{
            rsDanggota :{},
            tgl : {}
        },
        methods: {
            pageNext() {

            }
        }
        

    }
</script>

<style>
    .primary_2{
        background: #6454f3;
    }
</style>